<template>
  <header class="w-full m-auto relative top-0 left-0">
    <div id="logo" class="relative w-full">
      <router-link class="relative" to="/">
        <img src="@/assets/img/shared/logo.png" alt="SmartHQ Logo"
      /></router-link>
    </div>
    <nav id="mainNav" class="relative l-0 w-full sans">
      <router-link to="/" :class="{ selected: currentRouteName('dashboard') }"
        >Dashboard</router-link
      >
      <router-link to="/properties" :class="{ selected: currentRouteName('properties') }"
        >Properties</router-link
      >
      <a class="cursor-not-allowed" :class="{ selected: currentRouteName('appliances') }"
        >Appliances</a
      >
      <a class="cursor-not-allowed" :class="{ selected: currentRouteName('maintenance') }"
        >Maintenance</a
      >
      <a class="cursor-not-allowed" :class="{ selected: currentRouteName('store') }">Store</a>
    </nav>
    <div id="secondaryNav" class="w-full relative t-0">
      <nav class="relative text-center items-center">
        <div>
          <a class="text-white profile cursor-not-allowed">
            <img :src="profile.img" alt="Profile" />
            {{ profile.name }}</a
          >
        </div>
        <div>
          <a class="settings cursor-not-allowed" href="#">
            <img src="@/assets/img/shared/icon_settings.png" alt="settings icon" />
            Settings
          </a>
        </div>
        <div>
          <router-link to="/users" class="users" :class="{ selected: currentRouteName('users') }">
            <img
              src="@/assets/img/shared/icon_users_selected.png"
              v-show="currentRouteName('users')"
              alt="settings icon"
            />
            <img
              src="@/assets/img/shared/icon_users.png"
              v-show="!currentRouteName('users')"
              alt="settings icon"
            />
            Users
          </router-link>
        </div>
        <div>
          <a class="cursor-not-allowed help cursor-not-allowed" href="#">
            <img src="@/assets/img/shared/icon_info.png" alt="help icon" />
            Help
          </a>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
  const avatar = {
    susan: require('@/assets/img/shared/avatar_susan.png'),
    justin: require('@/assets/img/shared/avatar_justin.png'),
  };
  export default {
    components: {},
    data() {
      return {
        // currentRouteName: 'home',
        loggedIn: false,
        bundler: 'Parcel',
      };
    },
    //   mounted() {
    //     this.$store.watch(
    //       (state) => {
    //         return state.user.authenticated;
    //       },
    //       ((newVal, oldVal) => {
    //         if (newVal !== oldVal) {
    //           this.loggedIn = newVal;
    //           this.$forceUpdate();
    //         }
    //       }).bind(this),
    //       {}
    //     );
    //   },
    // },
    watch: {
      $route(newVal, oldVal) {
        if (newVal.name !== oldVal.name) {
          // console.log('route', newVal);
          // // this.currentRouteName = newVal.name;
          this.$forceUpdate();
        }
      },
    },
    computed: {
      profile() {
        return {
          name: this.$store.state.profile.name,
          img: avatar[this.$store.state.profile.slug],
        };
      },
    },
    methods: {
      currentRouteName(slug) {
        return this.$route.name == slug || this.$route.path.indexOf(slug) > 0;
      },
    },
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';

  .disabled {
    cursor: disabled;
  }
  header {
    z-index: 300;
    max-width: 297px;
    min-height: 1024px;
    width: calc(297 / 1440 * 100vw);
  }
  #logo {
    margin: 61px auto 0px;
    width: 100%;
    max-width: 218px;
    height: 63px;
    a {
      text-align: center;
      img {
        position: relative;
        max-width: 100%;
        height: auto;
      }
    }
  }
  #mainNav {
    max-width: 242px;
    @media only screen and (max-width: 1080px) {
      max-width: 222px;
    }

    border-top: #464444 1px solid;
    border-bottom: #464444 1px solid;
    margin: 24px auto 55px;
    padding: 45px 0px 5px;
    a {
      position: relative;
      display: block;
      margin: auto;
      margin-bottom: 40px;
      text-align: center;
      font-size: 22px;
      font-weight: 700;
      line-height: 40px;
      color: #979797;
      max-width: 160px;
      @media (hover: hover) {
        &:hover {
          color: #fff;
        }
      }

      &.selected {
        color: #fff;

        &:after {
          content: ' ';
          border-bottom: $yellow 2px solid;
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          width: 160px;
        }
      }
    }
  }
  #secondaryNav {
    // top: 586px;
    // left: 50%;
    // transform: translateX(-50%);
    margin: auto;
    // padding-top: 50px;
    a {
      position: relative;
      display: inline-block;
      font-size: 18px;
      color: #979797;
      padding-bottom: 30px;
      @media (hover: hover) {
        &:hover {
          color: #fff;
        }
      }
      img {
        display: block;
        margin: auto;
        padding-bottom: 7px;
      }
      &.settings img {
        width: 24px;
      }
      &.users img {
        width: 28px;
      }
      &.help img {
        width: 24px;
      }
      &.profile {
        color: #fff;
        font-size: 20px;
        font-weight: 800;
        // text-align: center;
        padding-bottom: 43px;
        img {
          width: 69px;
        }
      }

      &.selected {
        color: #fff;

        &:after {
          content: ' ';
          border-bottom: $yellow 2px solid;
          position: absolute;
          bottom: 27px;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          width: 80px;
        }
      }
    }
  }
</style>
