<template>
  <div class="relative prop-block relative col-span-1 mr-5 mb-5">
    <div @click="blockClick()" class="propertyBlock rounded col-span-3 top-0 p-2 w-full">
      <p class="font-extrabold text-14 text-white m-0 p-0">{{ name }}</p>
      <p class="text-10 text-ltgrey pt-0 pb-2">{{ address }}</p>
      <div class="w-full grid grid-cols-3 leading-none gap-3">
        <div class="col-span-1 text-10 text-green pl-1">ONLINE</div>
        <div class="col-span-2 text-24 text-green">{{ applianceCounts.online }}</div>
        <div class="col-span-1 text-10 text-yellow pl-1 relative -top-1">OFFLINE</div>
        <div class="col-span-2 text-24 text-yellow relative -top-1">
          {{ applianceCounts.offline }}
        </div>
        <div class="col-span-1 text-10 text-red pl-1 relative -top-2">ERRORS</div>
        <div class="col-span-2 text-24 text-red relative -top-2">{{ applianceCounts.errors }}</div>
      </div>
      <div class="text-10 text-ltgrey">PROPERTY MANAGER</div>
      <div class="text-14 text-white">{{ manager }}</div>
      <div class="absolute bottom-0 left-0 w-full pt-2 pb-1 pr-1 grey">
        <div class="text-12 more float-right text-ltgrey">MORE <span>></span></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'dashboardPropertyBlock',
    props: {
      name: {
        type: String,
        default: 'Property Name',
      },
      address: {
        type: String,
        default: '123 Main St. City State 12345',
      },
      manager: {
        type: String,
        default: 'First Last',
      },
      applianceCounts: {
        type: Object,
        default: () => ({
          total: 0,
          online: 0,
          offline: 0,
          errors: 0,
        }),
      },
      url: {
        type: String,
        default: '/properties/show',
      },
    },
    components: {},
    data() {
      return {};
    },
    computed: {},
    mounted() {},
    watch: {},
    methods: {
      onOver: function() {},
      onLEave: function() {},
      blockClick: function() {
        if (this.url) {
          this.$router.push(this.url);
        } else {
          return;
        }
      },
    },
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';

  .propertyBlock {
    border: #2d2d2a 4px solid;
    min-height: 230px;
    min-width: 197px;
    position: relative;
    cursor: pointer;
    .more {
      color: #5a5a5a;
    }

    &:hover {
      box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.5);
      &:after {
        content: ' ';
        position: absolute;
        top: -0.25rem;
        left: -0.25rem;
        width: calc(100% + 0.5rem);
        height: calc(100% + 0.5rem);
        // width: calc(100% + 1.0rem);
        // height: calc(100% + 1.0rem);
        border-radius: 10px;
        border: 1px $yellow solid;
      }
      .more {
        color: #fff;
        span {
          color: $yellow;
        }
      }
    }
  }
</style>
