import Vue from 'vue';
import Vuex from 'vuex';
import { getDataByHostname } from '../utils/dataUtil';
const appData = getDataByHostname();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    modalActive: false,
    timezoneOffset: 0,
    timezone: '',
    path: 'home',
    authURL: 'http://165.22.4.29/auth/local',
    api: 'http://165.22.4.29/',
    /*
    process.env.BASE_URL.indexOf('localhost') > -1
    http://165.22.4.29/
        ? 'http://localhost:1337/'
        : process.env.BASE_URL.indexOf('shun.io') > -1
        ? 'http://gpp.shun.io/engine/'
        : 'http://globalpeacepractice.org/engine/',
    */

    user: {
      authenticated: false,
      usernname: '',
      firstname: '',
      lastname: '',
      timezone: '',
      token: '',
    },

    users: appData.users,

    clientName: appData.clientName || '',
    profile: appData.profile || {
      name: '',
      path: '',
    },
    properties: appData.properties || [],
    propertyLabels: appData.propertyLabels || {
      room: 'Room',
    },
    applianceLabels: appData.applianceLabels || {
      zoneline: {
        name: '',
        nickname: '',
        type: '',
        modelNumber: '',
        servicedBy: '',
      },
    },
    tasks: appData.tasks || [
      {
        id: 1,
        name: 'Repair service ticket sent for Suite 101',
        description: '',
        completed: false,
        propertyName: '',
        buildingName: '',
      },
      {
        id: 2,
        name: 'Suite 406 zoneline requires filter cleaning',
        description: '',
        completed: true,
        propertyName: '',
        buildingName: '',
      },
      {
        id: 3,
        name: 'Dishwasher repair complete in suite 302',
        description: '',
        completed: true,
        propertyName: '',
        buildingName: '',
      },
    ],
  },

  getters: {
    userInfo: (state) => (target) => {
      return state.user[target];
    },
    applianceLabels: (state) => (key = null) => {
      return key ? state.applianceLabels[key] : state.applianceLabels;
    },
    propertyLabels: (state) => (key = null) => {
      return key ? state.propertyLabels[key] : state.propertyLabels;
    },
    tasks: (state) => (key = null) => {
      return state.tasks;
    },
  },

  mutations: {
    setUserStatus(state, payload) {
      state.user[payload.target] = payload.status;
    },
    setState(state, payload) {
      state[payload.target] = payload.value;
    },
  },

  actions: {
    setUserStatus(context, payload) {
      context.commit('setUserStatus', payload);
    },
    setState(context, payload) {
      context.commit('setState', payload);
    },
  },
});
