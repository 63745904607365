<template>
  <div id="tasks" class="relative w-full m-0 mb-5">
    <div class="rounded grey p-4">
      <h1 class="relative w-full font-extrabold text-white text-xl pb-5">
        Tasks
        <router-link
          to="/tasks"
          class="relative top-2 cursor-pointer float-right font-regular text-xs"
          >SEE ALL ></router-link
        >
      </h1>
      <ul class="pr-7">
        <li>Repair service ticket sent for {{ roomLabel }} 101</li>
        <li>{{ roomLabel }} 406 zoneline requires filter cleaning</li>
        <li>Dishwasher repair complete in {{ roomLabel.toLowerCase() }} 302</li>
      </ul>
      <h4 class="pt-3 text-white text-xs font-extrabold">
        <router-link to="/assign-task">ADD A TASK <span class="text-yellow">></span></router-link>
      </h4>
    </div>
  </div>
</template>

<script>
  export default {
    props: {},
    components: {},
    data() {
      return {};
    },
    computed: {
      roomLabel() {
        return this.$store.getters.propertyLabels('room');
      },
    },
    mounted() {},
    watch: {},
    methods: {
      resetState: function() {},
    },
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';
  #tasks {
    h1 {
      a {
        color: #c4c4c4;
        font-weight: 400;
        @media (hover: hover) {
          &:hover {
            color: #fff;
          }
        }
      }
    }
    ul {
      list-style-position: outside;
      list-style-type: none;
      li {
        padding-left: 2.5em;
        color: #c4c4c4;
        position: relative;
        margin-bottom: 1em;
        &:before {
          content: '•';
          position: absolute;
          top: 0px;
          left: 0px;
          display: block;

          // padding:0px 8px;
          color: $yellow;
          line-height: 0.55em;
          font-size: 40px;
        }
      }
    }
  }
</style>
