<template>
  <div id="quicklinks" class="relative w-full m-0 mb-5">
    <div class="rounded grey p-4">
      <h1 class="w-full font-extrabold text-white text-xl pb-3">Quick Links</h1>
      <p class="pb-5 text-md">
        <span class="pr-3"
          ><img class="inline-block" src="@/assets/img/dash_quickicon_1.svg" alt=""/></span
        >Review {{ roomLabel }}s >
      </p>
      <p class="pb-5 text-md">
        <span class="pr-3"
          ><img class="inline-block" src="@/assets/img/dash_quickicon_2.svg" alt=""/></span
        >Add User >
      </p>
      <p class="pb-5 text-md">
        <span class="pr-3"
          ><img class="inline-block" src="@/assets/img/dash_quickicon_3.svg" alt=""/></span
        >Report an Issue >
      </p>
      <p class="text-md">
        <span class="pr-3"
          ><img class="inline-block" src="@/assets/img/dash_quickicon_4.svg" alt=""/></span
        >Manage Appliances >
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      posTop: {
        type: Number,
        default: 30,
      },
      posLeft: {
        type: Number,
        default: 30,
      },
    },
    components: {},
    data() {
      return {};
    },
    computed: {
      roomLabel() {
        return this.$store.getters.propertyLabels('room');
      },
    },
    mounted() {},
    watch: {},
    methods: {
      resetState: function() {},
    },
  };
</script>
<style lang="scss" scope>
  #quicklinks {
    p {
      color: #c4c4c4;
      span {
        display: inline-block;
        width: 42px;
      }
    }
  }
</style>
