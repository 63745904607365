import { createProperties, createApplianceCounts } from '../utils/dataUtil';

const BUILDING_MANAGER_NAME = 'Grant Dutchak';
const BUILDING_MANAGER_EMAIL = 'grant.dutchak@example.com';

const properties = [
  {
    name: 'Emerald Place',
    address: '111 Calgary Way',
    applianceCounts: { total: 364, online: 354, offline: 10, errors: 5 },
    url: '/properties/show',
    units: 97,
    managerName: 'Eric the Quitter',
    managerEmail: 'eric.quitter@example.com',
    buildings: [
      {
        name: '1000',
        address: '',
        units: 97, // units === rooms
        managerName: BUILDING_MANAGER_NAME,
        managerEmail: BUILDING_MANAGER_EMAIL,
        url: '/properties/show/building-1',
        applianceCounts: { total: 90, online: 85, offline: 5, errors: 3 },
        floors: [
          {
            name: 'Floor 1',
            units: 25,
            applianceCounts: {
              total: 35,
              online: 30,
              offline: 5,
              errors: 3,
              occupied: { totalUnits: 20, online: 17, offline: 3, errors: 2 },
              vacant: { totalUnits: 3, online: 8, offline: 2, errors: 1 },
              cleaning: { totalUnits: 2, online: 5, offline: 0, errors: 1 },
            },
            //occupancyCounts: createOccupancyCounts(floor1Counts),
            managerName: BUILDING_MANAGER_NAME,
            url: '/properties/show/building-1/floor-1',
            address: '123 Suites',
          },
          {
            name: 'Floor 2',
            units: 24,
            applianceCounts: {
              total: 34,
              online: 30,
              offline: 4,
              errors: 3,
              occupied: { totalUnits: 14, online: 16, offline: 3, errors: 2 },
              vacant: { totalUnits: 6, online: 8, offline: 2, errors: 1 },
              cleaning: { totalUnits: 4, online: 5, offline: 0, errors: 1 },
            },
            managerName: BUILDING_MANAGER_NAME,
            url: '',
            address: '123 Suites',
          },

          {
            name: 'Floor 3',
            units: 24,
            applianceCounts: {
              total: 34,
              online: 30,
              offline: 4,
              errors: 3,
              occupied: { totalUnits: 19, online: 16, offline: 3, errors: 2 },
              vacant: { totalUnits: 3, online: 8, offline: 2, errors: 1 },
              cleaning: { totalUnits: 2, online: 5, offline: 0, errors: 1 },
            },
            managerName: BUILDING_MANAGER_NAME,
            url: '',
            address: '123 Suites',
          },
          {
            name: 'Floor 4',
            units: 24,
            applianceCounts: {
              total: 34,
              online: 30,
              offline: 4,
              errors: 3,
              occupied: { totalUnits: 21, online: 16, offline: 3, errors: 2 },
              vacant: { totalUnits: 2, online: 8, offline: 2, errors: 1 },
              cleaning: { totalUnits: 1, online: 5, offline: 0, errors: 1 },
            },
            managerName: BUILDING_MANAGER_NAME,
            url: '',
            address: '123 Suites',
          },
        ],
      },
      {
        name: '2000',
        applianceCounts: { total: 123, online: 115, offline: 8, errors: 3 },
        managerName: BUILDING_MANAGER_NAME,
        managerEmail: BUILDING_MANAGER_EMAIL,
        floors: [],
        url: '',
      },
      {
        name: '3000',
        applianceCounts: { total: 146, online: 136, offline: 10, errors: 5 },
        managerName: BUILDING_MANAGER_NAME,
        managerEmail: BUILDING_MANAGER_EMAIL,
        floors: [],
        url: '',
      },
    ],
  },
  {
    name: 'Maplecrest',
    address: '222 Calgary Way',
    applianceCounts: { total: 325, online: 300, offline: 25, errors: 4 },
    url: '',
    units: 110,
    managerName: 'Michael Walter',
    managerEmail: 'michael.walter@example.com',
  },
  {
    name: 'Yorkville',
    address: '333 Calgary Way',
    applianceCounts: { total: 325, online: 300, offline: 25, errors: 4 },
    url: '',
    units: 115,
    managerName: 'Michael Walter',
    managerEmail: 'michael.walter@example.com',
  },
];

const carlisleData = {
  clientName: 'Carlisle',
  profile: {
    name: 'Grant Dutchak',
    slug: 'justin',
  },
  users: [
    {
      name: 'Eric the Quitter',
      title: 'Property Manager 2',
      email: 'eric.gallant@example.com',
      phone: '123-456-7890',
    },
    {
      name: 'Grant Dutchak',
      title: 'Owner',
      email: 'grant.dutchak@example.com',
      phone: '123-456-7890',
    },
    {
      name: 'Michael Walter',
      title: 'Property Manager',
      email: 'michael.walter@example.com',
      phone: '123-456-7890',
    },
    {
      name: 'Wayne Kavanagh',
      title: 'Maintenance',
      email: 'wayne.kavanagh@example.com',
      phone: '123-456-7890',
    },
  ],
  properties,
  applianceLabels: {
    zoneline: {
      name: 'VTAC',
      nickname: 'VTAC',
      type: 'VTAC',
      modelNumber: 'AZ95H12DAC',
      servicedBy: 'Wayne Kavanagh',
    },
  },
  propertyLabels: {
    room: 'Suite',
  },
  tasks: [
    {
      id: 1,
      name: 'Repair service ticket sent for Suite 101',
      description: 'Can you go take a look at the unit in Suite 101, it looks like it is offline.',
      completed: false,
      buildingName: '1000',
    },
    {
      id: 2,
      name: 'Suite 406 zoneline requires filter cleaning',
      description: '',
      completed: true,
      buildingName: '1000',
    },
    {
      id: 3,
      name: 'Dishwasher repair complete in suite 302',
      description: '',
      completed: true,
      buildingName: '1000',
    },
  ],
};

export default carlisleData;
