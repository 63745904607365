import { demoData, carlisleData } from '../data';

// Client specific data lookup
const dataLookupByDomain = {
  localhost: carlisleData,
  'carlisle.smarthqdemo.com': carlisleData,
};

export function getDomain() {
  if (!window) {
    return null;
  }
  const url = window.location;
  return url.hostname.replace('www.', '');
}

export function getDataByHostname() {
  const domain = getDomain();
  if (!domain) {
    return demoData;
  }
  const data = dataLookupByDomain[domain] || demoData;
  return data;
}
