<template>
  <div id="container" class="relative w-screen">
    <TopBanner />
    <div class="min-h-screen md:flex">
      <div id="sbContainer" class="flex-none w-full md:max-w-xs grey text-white">
        <Menu></Menu>
      </div>
      <div class="flex-1 dkgrey px-5">
        <Search></Search>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
  import Menu from './components/sidebar.vue';
  import Search from './components/search.vue';
  import TopBanner from './components/topBanner.vue';

  export default {
    components: {
      Menu,
      Search,
      TopBanner,
    },
    data() {
      return {
        currentComponent: 'dashboard',
        bundler: 'Parcel',
      };
    },
    mounted() {
      //const dataByHostName = getDataByHostname();
      // Could put this in vuex but we just need a very simple store for this demo app
      // attaching to window object to avoid having to import it in every component
      //window.$appState = dataByHostName;
      this.$store.watch(
        (state) => {
          return state.path;
        },
        ((newVal, oldVal) => {
          if (newVal !== oldVal) {
            this.currentComponent = newVal;
            // this.$forceUpdate();
          }
        }).bind(this),
        {}
      );
    },
    watch: {},
    methods: {},
  };
</script>
<style lang="scss" scope>
  @import url('../tailwind.css');
  @import 'styles/typovars';
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  #demoAlert {
    font-weight: 800;
    font-size: 14px;
    text-align: center;
    margin: auto;
    height: 40px;
    line-height: 40px;
    z-index: 3000;
  }
  #sbContainer {
    @media only screen and (max-width: 1080px) {
      max-width: 16rem;
    }
  }
  .flex-none,
  .flex-1 {
    position: relative;
  }
</style>
