<template>
  <div class="max-main search-header w-full relative grid grid-cols-5">
    <div id="searchHolder" class="lg:col-start-4 md:col-start-1 lg:col-span-2 md:col-span-5">
      <div class="relative top-5 left-0 pr-7 flex">
        <input type="text" class="flex-grow search py-2" placeholder="Search" />
        <a class="flex-0 alert" href="#">
          <img class="relative" src="@/assets/img/shared/icon_alert.png" alt="34 Alerts" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scope>
#searchHolder {
  // margin-right: 35px;
  padding-top: 44px;
  padding-bottom: 44px;
}
input {
  // width: 302px;
  // height: 38px;
  background-color: #2d2d2a;
  border-radius: 10px;
  color: #6e6e6e;

  &::placeholder {
    padding-left: 15px;
    color: #6e6e6e;
  }
}
.alert {
  position: relative;
  width: 57px;
  height: 40px;
  display: inline-block;
  left: 33px;
  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
