import { createProperties, createApplianceCounts } from '../utils/dataUtil';

const properties = [
  {
    name: 'Orchard Gardens',
    address: '123 Main St. City State 12345',
    applianceCounts: { total: 3000, online: 2850, offline: 150, errors: 18 },
    url: '/properties/show',
    units: 200,
    buildings: [
      {
        name: 'Building 1',
        address: '',
        units: 40, // units === rooms
        manager: '',
        url: '/properties/show/building-1',
        applianceCounts: { total: 1000, online: 950, offline: 50, errors: 15 },
        floors: [
          {
            name: 'Floor 1',
            rooms: [
              {
                name: 'Room 132',
              },
            ],
            applianceCounts: {
              total: 24,
              online: 20,
              errors: 3,
              occupied: { totalUnits: 20, online: 17, offline: 3, errors: 2 },
              vacant: { totalUnits: 10, online: 8, offline: 2, errors: 0 },
              cleaning: { totalUnits: 10, online: 10, offline: 0, errors: 1 },
            },
          },
          {
            name: 'Floor 2',
            rooms: [
              {
                name: 'Room 132',
              },
            ],
            applianceCounts: {
              total: 24,
              online: 20,
              errors: 3,
              occupied: { totalUnits: 20, online: 20, offline: 3, errors: 2 },
              vacant: { totalUnits: 4, online: 4, offline: 2, errors: 0 },
              cleaning: { totalUnits: 1, online: 1, offline: 0, errors: 1 },
            },
          },
          {
            name: 'Floor 3',
            rooms: [
              {
                name: 'Room 132',
              },
            ],
            applianceCounts: {
              total: 24,
              online: 20,
              errors: 3,
              occupied: { totalUnits: 20, online: 20, offline: 3, errors: 2 },
              vacant: { totalUnits: 4, online: 4, offline: 2, errors: 0 },
              cleaning: { totalUnits: 1, online: 1, offline: 0, errors: 1 },
            },
          },
          {
            name: 'Floor 4',
            rooms: [
              {
                name: 'Room 132',
              },
            ],
            applianceCounts: {
              total: 24,
              online: 20,
              errors: 3,
              occupied: { totalUnits: 20, online: 20, offline: 3, errors: 2 },
              vacant: { totalUnits: 4, online: 4, offline: 2, errors: 0 },
              cleaning: { totalUnits: 1, online: 1, offline: 0, errors: 1 },
            },
          },
        ],
      },
      {
        name: 'Building 2',
        applianceCounts: { total: 1000, online: 975, offline: 25, errors: 15 },
        floors: [],
      },
      {
        name: 'Building 3',
        applianceCounts: { total: 1000, online: 945, offline: 55, errors: 15 },
        floors: [],
      },
    ],
  },
  {
    name: 'Shady Meadow',
    applianceCounts: { total: 3000, online: 2800, offline: 200, errors: 20 },
  },
  { name: 'Oak Glenn', applianceCounts: { total: 3000, online: 2800, offline: 200, errors: 20 } },
  {
    name: 'Covered Bridge',
    applianceCounts: { total: 3000, online: 2800, offline: 200, errors: 20 },
  },
  { name: 'Twin Peaks', applianceCounts: { total: 3000, online: 2800, offline: 200, errors: 20 } },
  {
    name: 'Orange Grove',
    applianceCounts: { total: 3000, online: 2800, offline: 200, errors: 20 },
  },
  { name: 'The Hills', applianceCounts: { total: 3000, online: 2800, offline: 200, errors: 20 } },
  {
    name: 'The Residences',
    applianceCounts: { total: 3000, online: 2800, offline: 200, errors: 20 },
  },
  {
    name: 'The Gates of Mordor',
    applianceCounts: { total: 3000, online: 2800, offline: 200, errors: 20 },
  },
  {
    name: 'Sunrise Park',
    applianceCounts: { total: 3000, online: 2800, offline: 200, errors: 20 },
  },
];

const demoData = {
  clientName: 'Demo',
  profile: {
    slug: 'susan',
    name: 'Susan Jones',
  },
  properties,
  propertyLabels: {
    room: 'Room',
  },
  users: [
    {
      name: 'Susan Jones',
      title: 'Owner',
      email: 'susan.jones@example.com',
      phone: '555-555-5555',
    },
    {
      name: 'Janet Evans',
      title: 'Property Manager',
      email: 'janet.evans@example.com',
      phone: '555-555-5555',
    },
    {
      name: '',
      title: 'Property Manager 2',
      email: '',
      phone: '',
    },
    {
      name: 'Bob Stevens',
      title: 'Maintenance',
      email: 'bob.stevens@example.com',
      phone: '555-555-5555',
    },
  ],
  applianceLabels: {
    zoneline: {
      name: 'Zoneline',
      nickname: 'WAC',
      type: 'Zoneline',
      modelNumber: 'CFE28UP2MCS1',
      servicedBy: 'Bob Stevens',
    },
  },
  tasks: [
    {
      id: 1,
      name: 'Repair service ticket sent for Suite 101',
      description: 'Can you go take a look at the unit in Suite 101, it looks like it is offline.',
      completed: false,
      buildingName: '1',
    },
    {
      id: 2,
      name: 'Suite 406 zoneline requires filter cleaning',
      description: '',
      completed: true,
      buildingName: '1',
    },
    {
      id: 3,
      name: 'Dishwasher repair complete in suite 302',
      description: '',
      completed: true,
      buildingName: '1',
    },
  ],
};

export default demoData;
