<template>
  <section id="propertyNames" class="w-full max-main">
    <div class="flex justify-between grid grid-cols-5">
      <div
        v-if="properties && properties.length"
        class="lg:col-span-3 md:col-span-5 flex items-stretch flex-wrap flex-row-start lg:order-1 md:order-2 mb-5"
      >
        <propertyblock
          v-for="(property, index) in properties"
          :key="index"
          :name="property.name"
          :units="property.units"
          :manager="property.managerName"
          :managerEmail="property.managerEmail"
          :applianceCounts="property.applianceCounts"
          :address="property.address"
          :url="property.url"
        />
      </div>
      <div class="lg:col-span-2 md:col-span-5 gap-5 lg:order-2 md:order-1">
        <quicklinks></quicklinks>
        <tasks></tasks>
      </div>
    </div>
    <div class="gradient-floor fixed bottom-0 w-full left-0 pointer-events-none"></div>
  </section>
</template>

<script>
  import propertyblock from '@/components/dashboardPropertyBlock.vue';
  import quicklinks from '@/components/dashboardQuickLinks.vue';
  import tasks from '@/components/dashboardTasks.vue';
  import { getDataByHostname } from '../utils/dataUtil';
  export default {
    components: { propertyblock, quicklinks, tasks },
    data() {
      return {
        properties: [],
      };
    },
    mounted() {
      const dataByHostName = getDataByHostname();
      this.properties = dataByHostName.properties || [];
    },
    watch: {},
    methods: {},
  };
</script>
<style lang="scss" scope>
  .gradient-floor {
    margin-left: 20rem;
    height: 220px;
    background-image: linear-gradient(
      0deg,
      #262524 0%,
      rgba(38, 37, 36, 0.96) 24%,
      rgba(38, 37, 36, 0) 100%
    );
    @media only screen and (max-width: 1080px) {
      margin-left: 16rem;
    }
  }
</style>
