<template>
  <div class='top-banner relative mb-4'>
    <div
      class=
        "absolute w-full top-0 left-0 bg-brand-orange-default text-center font-bold text-black z-10"
    >
      <div class="flex items-center">
        <div class="top-banner__tab top-banner__tab-active bg-brand-gray-darker text-brand-gray-light">
          PROPERTY
        </div>
        <div class="bg-brand-gray-dark text-black top-banner__tab">
          <a href="https://pensive-golick-6cb2e2.netlify.app/">LAUNDRY</a>
        </div>
        <div class="ml-auto mr-6">DEMO SITE</div>
      </div>
    </div>
  </div>
</template>

<style>
.top-banner {
  max-width: 1450px;
}
.top-banner__tab {
  width: 218px;
  height: 46px;
  line-height: 46px;
}
.bg-brand-orange-default {
  background-color: #F2A900
}
.text-brand-gray-light {
  color: #f5f5f5;
}

.bg-brand-gray-darker {
  background-color: #272624
}

.bg-brand-gray-dark {
  background-color: #333333
}

</style>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
};
</script>
