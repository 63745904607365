import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/dashboard.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/users',
    name: 'users',
    /* webpackChunkName: "users" */
    component: () => import('../views/usersList.vue'),
  },
  {
    path: '/users/bob-stevens',
    name: 'bob-stevens',
    /* webpackChunkName: "users" */
    component: () => import('../views/usersUser.vue'),
  },
  {
    path: '/assign-task',
    name: 'assign-task',
    /* webpackChunkName: "assignTask" */
    component: () => import('../views/assignTask.vue'),
  },
  {
    path: '/properties',
    name: 'properties',
    /* webpackChunkName: "properties" */
    component: () => import('../views/properties.vue'),
  },
  {
    path: '/properties/show',
    name: 'properties-buildings',
    /* webpackChunkName: "properties" */
    component: () => import('../views/propertiesBuildingsList.vue'),
  },
  {
    path: '/properties/show/batch-command',
    name: 'batch-command',
    /* webpackChunkName: "properties" */
    component: () => import('../views/propertiesBuildingsBatchCommand.vue'),
  },
  {
    path: '/properties/show/building-1',
    name: 'properties-buildings-floors',
    component: () => import('../views/propertiesBuildingsFloorsList.vue'),
  },
  {
    path: '/properties/show/building-1/floor-1',
    name: 'properties-buildings-floors-rooms',
    component: () => import('../views/propertiesBuildingsFloorsRoomsList.vue'),
  },
  {
    path: '/properties/show/building-1/floor-1/room-101',
    name: 'properties-buildings-floors-rooms-room',
    component: () => import('../views/propertiesBuildingsFloorsRoomsRoom.vue'),
    children: [
      {
        path: 'zoneline',
        component: () => import('../components/roomZoneline.vue'),
        props: true,
        meta: {
          showModal: true,
        },
      },
      {
        path: 'dishwasher',
        component: () => import('../components/roomDishwasher.vue'),
        props: true,
        meta: {
          showModal: true,
        },
      },
      {
        path: 'dfs',
        component: () => import('../components/ductFreeSplit/roomDuctFreeSplit.vue'),
        props: true,
        meta: {
          showModal: true,
        },
      },
    ],
  },
  {
    path: '/tasks',
    name: 'tasks',
    /* webpackChunkName: "users" */
    component: () => import('../views/tasks.vue'),
  },
  {
    path: '/users',
    name: 'users',
    /* webpackChunkName: "users" */
    component: () => import('../views/users.vue'),
  },
  // {
  //   path: "/help",
  //   name: "help",
  //   /* webpackChunkName: "store" */
  //   component: () => import('../views/help.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
